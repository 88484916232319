<template>
  <div>
    <v-card class="rounded-lg mb-5">
      <v-card-text class="py-1">
        <v-row align="center">
          <v-col cols="12" md="4">
            <v-list two-line subheader class="pb-0">
              <v-list-item>
                <v-list-item-action class="mr-4">
                  <v-icon>mdi-clock-outline</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-body-1 font-weight-bold">
                      {{
                      $t("statistic.period.title")
                      }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text-body-2 grey--text">
                      {{
                      $t("statistic.period.help")
                      }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="8">
            <div class="px-0 d-flex flex-wrap justify-end">
              <div :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }" class="mr-2">
                <v-btn @click="refresh()" :disabled="loading.summary">
                  <v-icon small left>mdi-refresh</v-icon>
                  {{ $t("btn.refresh") }}
                </v-btn>
              </div>
              <div>
                <v-btn
                  @click="dialog.daterange.display = true"
                  color="primary"
                  :disabled="loading.summary"
                >
                  <v-icon small left>mdi-calendar-range</v-icon>
                  {{ daterange.formatted }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <statistic-summary :summary="summary" :loading="loading.summary"></statistic-summary>
    <v-card class="rounded-lg mt-5">
      <v-card-text class="py-1">
        <v-row align="center">
          <v-col cols="12" md="4">
            <v-list two-line subheader class="pb-0">
              <v-list-item>
                <v-list-item-action class="mr-4">
                  <v-icon>mdi-clock-outline</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold text-body-1">{{ $t('statistic.period.title2') }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="grey--text text-body-2">{{ $t('statistic.period.help2') }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="8">
            <div class="px-0 d-flex flex-wrap justify-end align-end">
              <div :class="{'mb-2': $vuetify.breakpoint.smAndDown}">
                <v-btn @click="refreshDetails()" :disabled="loading.refreshDetails">
                  <v-icon small left>mdi-refresh</v-icon>
                  {{ $t('btn.refresh') }}
                </v-btn>
              </div>
              <div :class="{'mb-2': $vuetify.breakpoint.smAndDown}">
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      @click="exportStatisticByMonth()"
                      :disabled="loading.details"
                      :loading="loading.exportStatisticByMonth"
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                      class="ml-2"
                    >{{ $t('btn.export') }}</v-btn>
                  </template>
                  <span>{{ $t('statistic.btn.export') }}</span>
                </v-tooltip>
              </div>
              <div :class="{'mb-2': $vuetify.breakpoint.smAndDown}">
                <v-btn
                  @click="dialog.datemonth.display = true"
                  color="primary"
                  :disabled="loading.refreshDetails"
                  class="ml-2"
                >
                  <v-icon small left>mdi-calendar-range</v-icon>
                  {{ datemonth.formatted.toUpperCase() }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <div class="text-body-1 font-weight-medium">
              <span class="secondary-font">{{ $t('bulksms.statistic.details.status.title') }}</span>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="min-height: 200px;">
            <chart
              :options="chartByStatus.options"
              :data="chartByStatus.data"
              :loading="loading.details"
            ></chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <div class="text-body-1 font-weight-medium">
              <span class="secondary-font">{{ $t('bulksms.statistic.details.telcos.title') }}</span>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="min-height: 200px;">
            <chart
              :options="chartByTelcos.options"
              :data="chartByTelcos.data"
              :loading="loading.details"
            ></chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <div class="text-body-1 font-weight-medium">
              <span class="secondary-font">{{ $t('bulksms.statistic.details.days.title') }}</span>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <statistic-by-day
            :loading="loading.details"
            :stats="details.days"
            :total="details.total"
          ></statistic-by-day>
          <v-divider></v-divider>
          <v-card-actions class="pr-5" v-if="!loading.details">
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  text
                  @click="exportStatisticByDay()"
                  class="font-weight-bold"
                >{{ $t('btn.export') }}</v-btn>
              </template>
              <span>{{ $t('statistic.btn.export') }}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <div class="text-body-1 font-weight-medium">
              <span class="secondary-font">{{ $t('bulksms.statistic.details.statusAndDay.title') }}</span>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <chart
              :options="chartByStatusAndDays.options"
              :data="chartByStatusAndDays.data"
              :loading="loading.details"
              type="LineChart"
            ></chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <date-picker :dialog="dialog.datemonth" :options="{type: 'month'}" @confirm="changeDatemonth"></date-picker>
    <date-picker :dialog="dialog.daterange" @confirm="changeDaterange"></date-picker>
  </div>
</template>

<script>
import StatisticByDay from './by-day';
import Chart from "./../../statistics/chart";
import DatePicker from "./../../commons/datepicker";
import StatisticSummary from "./summary";
import { mapActions } from "vuex";

export default {
  created() {
    this.initDate();
    // init default stats
    this.details = this.getDefaultDetailsStats();
    this.chartByStatus = this.getDefaultChartByStatus();
    this.chartByTelcos = this.getDefaultChartByTelcos();

    // init stats
    this.getSummaryStats();
    this.getDetailsStats();
  },
  data: () => ({
    loading: {
      summary: false,
      refreshDetails: false,
      refresh: false,
      details: true,
      exportStatisticByMonth: false
    },
    daterange: {
      dates: [null, null],
      formatted: null
    },
    datemonth: {
      date: null,
      formatted: null
    },
    dialog: {
      daterange: {
        display: false
      },
      datemonth: {
        display: false
      }
    },
    summary: {
      sent: {
        delivered: 0,
        total: 0,
        undelivered: 0,
        acked: 0,
        pending: 0,
        details: {}
      },
      updated: null,
      nextUpdate: null
    },
    chartByStatus: {},
    chartByTelcos: {},
    chartByStatusAndDays: {},
    details: {}
  }),
  methods: {
    initDate() {
      const date = this.$moment();
      const range = date.format("YYYY-MM-DD");

      // date range
      this.daterange.dates = [range, range];
      this.daterange.formatted = range;

      // date month
      this.datemonth.date = date.startOf("month").format("YYYY-MM-DD");
      this.datemonth.formatted = date.format("YYYY-MMMM");
    },
    changeDaterange(dates) {
      const daterange = this.$utils.daterangeAlign(dates);
      if (!daterange) return;

      this.daterange.dates = daterange;
      if (this.$moment(daterange[0]).isSame(daterange[1])) {
        this.daterange.formatted = daterange[0];
      } else {
        this.daterange.formatted = daterange.join(" - ");
      }
      this.getSummaryStats();
    },
    changeDatemonth(date) {
      this.datemonth.date = date;
      this.datemonth.formatted = this.$moment(date, "YYYY-MM").format(
        "YYYY-MMMM"
      );
      this.getDetailsStats();
    },
    async getSummaryStats() {
      this.loading.summary = true;

      try {
        const params = {
          startdate: this.$moment(`${this.daterange.dates[0]} 00:00:00`).format(
            "X"
          ),
          enddate: this.$moment(`${this.daterange.dates[1]} 23:59:59`).format(
            "X"
          )
        };

        const response = await this.request({
          url: "/api/providers/bulksms/statistics/summary",
          params: params
        });
        const data = response.data;

        // set messages
        this.summary.sent = this.$bulksms.parseSummaryMessageStats(
          data.messages
        );
        // set update time
        this.summary.updated = data.updated;
        this.summary.nextUpdate = data.nextUpdate;
      } catch (error) {
        // empty
      }
      this.loading.summary = false;
    },
    async getDetailsStats() {
      const date = this.$moment(this.datemonth.date, "YYYY-MM");
      this.arrayOfDates = this.$bulksms.getArrayOfDates(date);

      this.loading.details = true;
      try {
        const params = {
          month: date.format("X")
        };

        const response = await this.request({
          url: "/api/providers/bulksms/statistics/messages",
          params: params
        });
        const details = this.$bulksms.parseDetailsStats(response.data);
        const $t = this.$t.bind(this);

        // set stats by day
        const days = this.$bulksms.parseDetailsStatsByAllDay(
          details.days,
          this.arrayOfDates
        );
        // set stats by days
        this.details.days = days;
        // set total stats
        this.details.total = details.total;
        // set total source
        this.details.sources = details.sources;

        // set data chart by status
        this.chartByStatus = this.$bulksms.parseChartByStatus(
          details.total,
          $t
        );
        // set data chart by telco
        this.chartByTelcos = this.$bulksms.parseChartByTelco(
          details.telcos,
          $t
        );
        // set data chart by status and days
        this.chartByStatusAndDays = this.$bulksms.parseChartByStatusAndDays(
          days,
          $t
        );
      } catch (error) {
        this.details = this.getDefaultDetailsStats();
        this.chartByStatus = this.getDefaultChartByStatus();
        this.chartByTelcos = this.getDefaultChartByTelcos();
      }
      this.loading.details = false;
    },
    getDefaultDetailsStats() {
      return {
        days: {},
        sources: {},
        total: {}
      };
    },
    getDefaultChartByTelcos() {
      return {
        data: [],
        options: {}
      };
    },
    getDefaultChartByStatus() {
      return {
        data: [],
        options: {}
      };
    },
    exportStatisticByDay() {
      const $t = this.$t.bind(this);
      const date = this.$moment(this.datemonth.date).format("MM-YYYY");
      this.$bulksms.exportStatisticByDay(
        date,
        this.details.days,
        this.details.total,
        $t
      );
    },
    async refresh() {
      this.loading.refresh = true;
      await this.getSummaryStats();
      this.loading.refresh = false;
    },
    async refreshDetails() {
      this.loading.refreshDetails = true;
      await this.getDetailsStats();
      this.loading.refreshDetails = false;
    },
    async exportStatisticByMonth() {},
    ...mapActions({ request: "request", notify: "notification/notify" })
  },
  components: {
    DatePicker,
    StatisticSummary,
    Chart,
    StatisticByDay
  }
};
</script>