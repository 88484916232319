<template>
  <div>
    <template v-if="!loading">
      <v-simple-table class="app-datatable">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                :style="$vuetify.breakpoint.name === 'xs' ? 'width: 90px' : '25%'"
                class="text-left"
              >{{ $t('bulksms.statistic.fields.date.title') }}</th>
              <th
                class="text-left"
                :style="$vuetify.breakpoint.name === 'xs' ? 'width: 80px' : '25%'"
              >{{ $t('bulksms.statistic.fields.sent.short_title') }}</th>
              <th
                class="text-left"
                :style="$vuetify.breakpoint.name === 'xs' ? 'width: 80px' : '25%'"
              >{{ $t('bulksms.statistic.fields.delivered.short_title') }}</th>
              <th
                class="text-left"
                :style="$vuetify.breakpoint.name === 'xs' ? 'width: 130px' : '25%'"
              >{{ $t('bulksms.statistic.fields.undelivered.short_title') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stat, date) in stats" :key="date">
              <td
                class="text-left"
              >{{ date }}</td>
              <td
                class="text-left"
              >{{ stat.total.total }}</td>
              <td
                class="text-left"
              >{{ stat.total.delivered }}</td>
              <td
                class="text-left"
              >{{ stat.total.undelivered }}</td>
            </tr>
            <tr>
              <td
                class="text-left font-weight-bold grey--text text--darken-4"
              >{{ $t('bulksms.statistic.fields.total.title2') }}</td>
              <td
                class="text-left font-weight-bold grey--text text--darken-4"
              >{{ total.total }}</td>
              <td
                class="text-left font-weight-bold grey--text text--darken-4"
              >{{ total.delivered }}</td>
              <td
                class="text-left font-weight-bold grey--text text--darken-4"
              >{{ total.undelivered }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <template v-else>
      <div style="min-height: 100px;">
        <div class="text-center container">
          <v-progress-circular size="50" indeterminate color="secondary"></v-progress-circular>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: function() {
        return {};
      }
    },
    total: {
      type: Object,
      default: function() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({})
};
</script>