<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium">{{ $t("dashboard.title") }}</div>
      <div class="text-body-2 grey--text">BulkSMS</div>
    </div>
    <bulksms-statistic></bulksms-statistic>
  </div>
</template>

<script>
import BulksmsStatistic from "./../bulksms/statistics/index";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("dashboard.title")
    };
  },
  components: {
    BulksmsStatistic
  }
};
</script>