<template>
  <v-dialog v-model="dialog.display" :return-value.sync="daterange" persistent width="290px">
    <v-date-picker
      :value="options.range ? daterange : date"
      scrollable
      @input="updateDatepicker"
      v-bind="options"
    >
      <v-spacer></v-spacer>
      <v-btn text @click="dialog.display = false">{{ $t("btn.cancel") }}</v-btn>
      <v-btn text color="primary" @click="confirm()"><span class="font-weight-bold">{{ $t("btn.ok") }}</span></v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {
          display: false
        };
      }
    },
    options: {
      default: function() {
        return {
          range: true
        };
      }
    }
  },
  data: () => ({
    daterange: [null, null],
    date: null
  }),
  methods: {
    confirm() {
      let date;
      if (this.options.range) {
        if (this.daterange.length === 1) {
          date = [this.daterange[0], this.daterange[0]];
        } else {
          date = this.daterange;
        }
      } else {
        date = this.date;
      }
      this.$emit("confirm", date);
      this.dialog.display = false;
    },
    updateDatepicker(value) {
      if (this.options.range) {
        this.daterange = value;
      } else {
        this.date = value;
      }
    }
  }
};
</script>