<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="primary" class="white--text" height="120">
            <v-card-title primary-title>
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 55 : 50"
                      >mdi-email-outline</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ summary.sent.total }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-2" v-if="summary.nextUpdate">
                    {{
                      $t("bulksms.statistic.fields.nextUpdate.title", {
                        time: $moment(summary.nextUpdate, "X").format(
                          "HH:mm:ss"
                        ),
                      })
                    }}
                  </div>
                  <div class="text-right text-body-1" v-else>
                    {{ $t("bulksms.statistic.fields.sent.title") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider dark></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("bulksms.statistic.fields.sent.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="teal darken-1" class="white--text" height="120">
            <v-card-title primary-title>
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 55 : 50"
                      >mdi-email-open-outline</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ summary.sent.delivered }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-2" v-if="summary.nextUpdate">
                    {{
                      $t("bulksms.statistic.fields.nextUpdate.title", {
                        time: $moment(summary.nextUpdate, "X").format(
                          "HH:mm:ss"
                        ),
                      })
                    }}
                  </div>
                  <div class="text-right text-body-1" v-else>
                    {{ $t("bulksms.statistic.fields.delivered.title") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider dark></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("bulksms.statistic.fields.delivered.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="amber darken-4" class="white--text" height="120"> 
            <v-card-title primary-title>
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 55 : 50"
                      >mdi-email-variant</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ summary.sent.pending }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-2" v-if="summary.nextUpdate">
                    {{
                      $t("bulksms.statistic.fields.nextUpdate.title", {
                        time: $moment(summary.nextUpdate, "X").format(
                          "HH:mm:ss"
                        ),
                      })
                    }}
                  </div>
                  <div class="text-right text-body-2" v-else>
                    {{ $t("bulksms.statistic.fields.pending.title") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider dark></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("bulksms.statistic.fields.pending.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg">
          <template v-if="!loading">
            <v-tabs
              background-color="white"
              grow
              show-arrows
              centered
              hide-slider
              height="60"
            >
              <v-tab>
                <v-badge color="primary" :content="String(summary.sent.total)">
                  <span class="font-weight-bold black--text">{{
                    $t("bulksms.statistic.fields.sent.short_title")
                  }}</span>
                </v-badge>
              </v-tab>
              <v-tab>
                <v-badge
                  color="primary"
                  :content="String(summary.sent.delivered)"
                >
                  <span class="font-weight-bold black--text">{{
                    $t("bulksms.statistic.fields.delivered.short_title")
                  }}</span>
                </v-badge>
              </v-tab>
              <v-tab>
                <v-badge
                  color="primary"
                  :content="String(summary.sent.pending)"
                >
                  <span class="font-weight-bold black--text">{{
                    $t("bulksms.statistic.fields.pending.short_title")
                  }}</span>
                </v-badge>
              </v-tab>
              <v-tab
                v-for="(count, detail) in summary.sent.details"
                :key="detail"
              >
                <v-badge color="primary" :content="String(count)">
                  <span class="font-weight-bold black--text">{{
                    $t(`bulksms.campaign.fields.dlr.${detail}`)
                  }}</span>
                </v-badge>
              </v-tab>
            </v-tabs>
          </template>
          <template v-else>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                  width="3"
                  size="30"
                ></v-progress-circular>
              </div>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
};
</script>