<template>
  <div>
    <dashboard-public></dashboard-public>
  </div>
</template>

<script>
import DashboardPublic from "./public";
export default {
  data: () => ({}),
  components: {
    DashboardPublic,
  },
};
</script>