<template>
  <div>
    <dashboard></dashboard>
  </div>
</template>

<script>
import Vue from "vue";
import Dashboard from "./../components/dashboard/index";
export default {
  beforeRouteEnter(to, from, next) {
    if (!Vue.prototype.$auth.check(["ROLE_CLIENT"])) {
      next({ name: "payment", params: to.params, replace: true });
    } else {
      next();
    }
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("dashboard.title")
    };
  },
  components: {
    Dashboard
  }
};
</script>